// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import type { RawSpanAttributes } from '@imprivata-cloud/common';
import {
  tracer,
  SpanNames,
  startRootSpan,
  recordException,
  endRootSpan,
} from '../../tracing';

export function startLogoutSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.LOGOUT, SpanNames.ROOT_SPAN);
}

export function endLogoutSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.LOGOUT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.LOGOUT);
  endRootSpan();
}

export function startGetTenantTypeSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.GET_TENANT_TYPE, SpanNames.ROOT_SPAN);
}

export function endGetTenantTypeSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_TENANT_TYPE, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_TENANT_TYPE);
  endRootSpan();
}

export function startCreateUserSessionTracingSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.CREATE_USER_SESSION,
    SpanNames.ROOT_SPAN,
  );
}

export function endCreateUserSessionTracingSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.CREATE_USER_SESSION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.CREATE_USER_SESSION);
}
