// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useState, useEffect } from 'react';
import { Select, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ActivityType,
  ActivitySubtype,
  TimePeriodType,
} from '@imprivata-cloud/adminapi-client';
import { Column } from '@ant-design/plots';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { ColumnConfig } from '@ant-design/plots';
import type { MappedChartDatum } from '../../../../api/types';
import type { TimePeriodOption } from '../../types';
import { useGetMappedTrendDataChartData } from '../../store/hooks';
import classes from './DashboardView.module.less';
import DashboardCard from '../dashboard-card/DashboardCard';
import { TimePeriodOptionIdentifier } from '../../types';
import RoundedDownArrowSvg from '../../../../assets/svg/rounded-down-arrow.svg';

const DashboardView: React.FC = () => {
  const { t } = useTranslation();
  const dashboardTotalsTranslations = t(['dashboard.totals'], {
    returnObjects: true,
  });

  const INTIAL_TIME_PERIOD_TYPE: TimePeriodOption = {
    timePeriodType: TimePeriodType.Day,
    optionType: TimePeriodOptionIdentifier.Last7Days,
    label: t('dashboard.time-period.last-7-days'),
  };

  // State to manage the selected time period
  const [timePeriodOption, setTimePeriodOption] = useState<TimePeriodOption>(
    INTIAL_TIME_PERIOD_TYPE,
  );

  const timeZoneId = moment.tz.guess(true);
  const {
    mappedChartData,
    chartTotals,
    isLoading,
    latestActivityDateTime,
    fetchTrendData,
    saveTimePeriodOption,
    loadTimePeriodOption,
  } = useGetMappedTrendDataChartData({
    startDate: getStartDate(timePeriodOption),
    endDate: moment(),
    timePeriodType: timePeriodOption.timePeriodType,
    activityTypes: [
      ActivityType.Identification,
      ActivityType.Verification,
      ActivityType.Enrollment,
    ],
    activitySubtypes: [
      ActivitySubtype.Success,
      ActivitySubtype.MultipleMatchesSuccess,
    ],
    timeZoneId,
  });

  const mappedChartDataRef = React.useRef<MappedChartDatum[] | undefined>();

  useEffect(() => {
    if (mappedChartData) {
      mappedChartDataRef.current = mappedChartData;
    }
  }, [mappedChartData]);

  useEffect(() => {
    const storedOption = loadTimePeriodOption();
    if (storedOption) {
      setTimePeriodOption(storedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for time interval changes
  const handleTimePeriodChange = (label: string) => {
    const selectedOption = timePeriodOptions.find(
      option => option.optionType === label,
    );
    if (selectedOption) {
      setTimePeriodOption(selectedOption);
      saveTimePeriodOption(selectedOption); // Save to localStorage

      // Trigger a new data request with the correct start date
      try {
        fetchTrendData({
          startDate: getStartDate(selectedOption),
          endDate: moment(),
          timePeriodType: selectedOption.timePeriodType,
          activityTypes: [
            ActivityType.Identification,
            ActivityType.Verification,
            ActivityType.Enrollment,
          ],
          activitySubtypes: [
            ActivitySubtype.Success,
            ActivitySubtype.MultipleMatchesSuccess,
          ],
          timeZoneId,
        });
      } catch (error) {
        message.error(t('dashboard.error-loading-data'));
      }
    }
  };

  const columnConfig: ColumnConfig = {
    data: mappedChartData,
    isStack: true,
    xField: 'label',
    yField: 'count',
    seriesField: 'activityType',
    color: ['#FFA600', '#BC5090', '#003F5C'],
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      reversed: true,
    } as const,
    style: {
      height: '100%',
      width: '100%',
      background: '#fff',
    },
    maxColumnWidth: 30,
  };

  const timePeriodOptions: TimePeriodOption[] = [
    {
      timePeriodType: TimePeriodType.Hour,
      optionType: TimePeriodOptionIdentifier.Last24Hours,
      label: t('dashboard.time-period.last-24-hours'),
    },
    {
      timePeriodType: TimePeriodType.Day,
      optionType: TimePeriodOptionIdentifier.Last7Days,
      label: t('dashboard.time-period.last-7-days'),
    },
    {
      timePeriodType: TimePeriodType.Day,
      optionType: TimePeriodOptionIdentifier.Last30Days,
      label: t('dashboard.time-period.last-30-days'),
    },
    {
      timePeriodType: TimePeriodType.Month,
      optionType: TimePeriodOptionIdentifier.Last12Months,
      label: t('dashboard.time-period.last-12-months'),
    },
  ];

  return (
    <>
      {isLoading && !mappedChartData ? (
        <Spin
          data-testid="dashboard-view-container"
          className={classes.spinner}
          spinning
        />
      ) : (
        <>
          <div
            className={classes.latestActivityDateTime}
            data-testid="dashboard-latest-activity-date-time"
          >
            {latestActivityDateTime
              ? `${t('dashboard.latest-activity-date-time')} ${moment(latestActivityDateTime).format('YYYY-MM-DD HH:mm:ss')}`
              : ''}
          </div>
          <div
            className={classes.container}
            data-testid="dashboard-view-container"
          >
            <div
              className={clsx('elevation-astra-1', classes.dashboardTop)}
              data-testid="column-chart-panel"
            >
              <div className={classes.columnChartWrapper}>
                <div className={classes.timePeriodSelect}>
                  <span className="subtitle">
                    {t('dashboard.chart.header')}
                  </span>
                </div>
                <div className={classes.columnChart}>
                  <Column {...columnConfig} loading={!!isLoading} />
                </div>
              </div>
              <div className={classes.dashboardCards}>
                  <Select
                    data-testid="time-period-select"
                    bordered={false}
                    dropdownRender={menu => (
                      <div className={classes.timePeriodSelectComponent}>
                        {menu}
                      </div>
                    )}
                    suffixIcon={
                      <img
                        src={RoundedDownArrowSvg}
                        alt="Select dashboard time period"
                        className={classes.downArrow}
                      />
                    }
                    value={timePeriodOption.optionType}
                    onChange={value => handleTimePeriodChange(value)}
                    options={timePeriodOptions.map(option => ({
                      value: option.optionType,
                      label: option.label,
                    }))}
                    className={clsx(classes.timePeriodSelect, classes.selectedItem)}
                  />
                {Object.entries(chartTotals).map(entry => {
                  const activityType = entry[0].toLowerCase();
                  return (
                    <React.Fragment key={`${activityType}_dashboard_card`}>
                      <DashboardCard
                        data={(entry[1] ?? 0).toString()}
                        label={
                          dashboardTotalsTranslations[
                            activityType as keyof typeof dashboardTotalsTranslations
                          ]
                        }
                      />
                    </React.Fragment>
                  );
                })}
                <DashboardCard data={' '} label={''} />
                <DashboardCard data={' '} label={''} />
                <DashboardCard data={' '} label={''} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export function getStartDate(option: TimePeriodOption) {
  switch (option.optionType) {
    case TimePeriodOptionIdentifier.Last24Hours:
      return moment().subtract(23, 'hours');
    case TimePeriodOptionIdentifier.Last7Days:
      return moment().subtract(6, 'days');
    case TimePeriodOptionIdentifier.Last30Days:
      return moment().subtract(29, 'days');
    case TimePeriodOptionIdentifier.Last12Months:
      return moment()
        .subtract(11, 'months')
        .set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    default:
      return moment().subtract(11, 'months');
  }
}

export default DashboardView;
