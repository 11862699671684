// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type {
  GetHl7MessageResponse,
  Hl7RuleConfiguration,
  Hl7SystemConfiguration,
  SearchHl7MessagesResponse,
} from '@imprivata-cloud/adminapi-client';
import type { RootAction } from '../../../../../store/rootAction';
import {
  hl7ErroredMessageCountActions,
  hl7RuleConfigurationDeleteActions,
  hl7RuleConfigurationGetActions,
  hl7RuleConfigurationSaveActions,
  hl7SystemConfigurationDeleteActions,
  hl7SystemConfigurationGetActions,
  hl7SystemConfigurationSaveActions,
  hl7MessageSearchActions,
  hl7MessageActions,
  hl7UnsentMessageCountActions,
  hl7SuccessMessageCountActions,
} from './actions';
import { invalidSessionAction } from '../../../../login/store/actions';

export interface Hl7MessageCount {
  loading: boolean;
  error: string | null;
  count: number;
}

export interface Hl7ConfigurationState {
  loading: boolean;
  error: string | null;
  systemConfiguration: Hl7SystemConfiguration | null;
  ruleConfigurations: Hl7RuleConfiguration[] | null;
  successMessages: Hl7MessageCount | null;
  erroredMessages: Hl7MessageCount | null;
  unsentMessages: Hl7MessageCount | null;
}

export interface Hl7MessageSearchState {
  loading: boolean;
  error: string | null;
  results: SearchHl7MessagesResponse;
  messages: Hl7MessageState[];
}

export interface Hl7MessageState {
  id: string;
  loading: boolean;
  error: string | null;
  message: GetHl7MessageResponse | null;
}

export interface Hl7CertificateState {
  processing: boolean;
  error: string | null;
}

export const initialState: Hl7ConfigurationState = {
  loading: false,
  error: null,
  systemConfiguration: null,
  ruleConfigurations: null,
  successMessages: null,
  erroredMessages: null,
  unsentMessages: null,
};

export const initialMessageSearchState: Hl7MessageSearchState = {
  loading: false,
  error: null,
  results: {
    pageNumber: 1,
    pageSize: 25,
    messages: [],
    totalMessages: 0,
  },
  messages: [],
};

export const initialCertificateState: Hl7CertificateState = {
  processing: false,
  error: null,
};

export const hl7ConfigurationReducer = combineReducers<Hl7ConfigurationState>({
  loading: createReducer<boolean>(initialState.loading)
    .handleAction(
      [
        hl7SystemConfigurationGetActions.request,
        hl7SystemConfigurationSaveActions.request,
        hl7RuleConfigurationGetActions.request,
        hl7RuleConfigurationSaveActions.request,
      ],
      () => true,
    )
    .handleAction(
      [
        hl7SystemConfigurationGetActions.cancel,
        hl7SystemConfigurationGetActions.success,
        hl7SystemConfigurationGetActions.failure,
        hl7SystemConfigurationSaveActions.cancel,
        hl7SystemConfigurationSaveActions.success,
        hl7SystemConfigurationSaveActions.failure,
        hl7SystemConfigurationDeleteActions.cancel,
        hl7SystemConfigurationDeleteActions.success,
        hl7SystemConfigurationDeleteActions.failure,
        hl7RuleConfigurationGetActions.cancel,
        hl7RuleConfigurationGetActions.success,
        hl7RuleConfigurationGetActions.failure,
        hl7RuleConfigurationSaveActions.cancel,
        hl7RuleConfigurationSaveActions.success,
        hl7RuleConfigurationSaveActions.failure,
        hl7RuleConfigurationDeleteActions.cancel,
        hl7RuleConfigurationDeleteActions.success,
        hl7RuleConfigurationDeleteActions.failure,
        invalidSessionAction.request,
      ],
      () => false,
    ),
  systemConfiguration: createReducer<Hl7SystemConfiguration | null, RootAction>(
    initialState.systemConfiguration,
  )
    .handleAction(
      [
        hl7SystemConfigurationGetActions.success,
        hl7SystemConfigurationSaveActions.request,
      ],
      (_, { payload }) => {
        return !payload
          ? null
          : {
              audience: payload?.audience,
              clientId: payload?.clientId,
              endpoint: payload?.endpoint,
              receivingFacility: payload?.receivingFacility,
              receivingApplication: payload?.receivingApplication,
            };
      },
    )
    .handleAction([invalidSessionAction.request], () => null),
  ruleConfigurations: createReducer<Hl7RuleConfiguration[] | null, RootAction>(
    initialState.ruleConfigurations,
  )
    .handleAction(
      [hl7RuleConfigurationGetActions.success],
      (_, { payload }) => {
        return !payload
          ? null
          : payload.map(config => {
              return {
                id: config?.id,
                displayName: config?.displayName,
                ruleType: config?.ruleType,
                ruleIdentifierMappings: config?.ruleIdentifierMappings,
                modifiedBy: config?.modifiedBy,
                modifiedTimestamp: config?.modifiedTimestamp,
              };
            });
      },
    )
    .handleAction(
      [hl7RuleConfigurationSaveActions.request],
      (_, { payload }) => {
        return [
          {
            id: payload?.id,
            displayName: payload?.displayName,
            ruleType: payload?.ruleType,
            ruleIdentifierMappings: payload?.ruleIdentifierMappings,
          },
        ];
      },
    )
    .handleAction([invalidSessionAction.request], () => null),
  error: createReducer<string | null, RootAction>(initialState.error)
    .handleAction(
      [
        hl7SystemConfigurationGetActions.request,
        hl7SystemConfigurationGetActions.success,
        hl7SystemConfigurationSaveActions.request,
        hl7SystemConfigurationSaveActions.success,
        invalidSessionAction.request,
      ],
      () => null,
    )
    .handleAction(
      [
        hl7SystemConfigurationGetActions.failure,
        hl7SystemConfigurationSaveActions.failure,
        hl7SystemConfigurationDeleteActions.failure,
        hl7RuleConfigurationGetActions.failure,
        hl7RuleConfigurationSaveActions.failure,
        hl7RuleConfigurationDeleteActions.failure,
      ],
      (_, { payload }) => payload.code || null,
    )
    .handleAction(
      [
        hl7SystemConfigurationGetActions.cancel,
        hl7SystemConfigurationSaveActions.cancel,
        hl7SystemConfigurationDeleteActions.cancel,
        hl7RuleConfigurationGetActions.cancel,
        hl7RuleConfigurationSaveActions.cancel,
        hl7RuleConfigurationDeleteActions.cancel,
      ],
      () => 'cancelled',
    ),
  successMessages: createReducer<Hl7MessageCount | null, RootAction>(
    initialState.successMessages,
  )
    .handleAction([hl7SuccessMessageCountActions.success], (_, { payload }) => {
      return {
        loading: false,
        error: null,
        count: payload,
      };
    })
    .handleAction([hl7SuccessMessageCountActions.request], _ => {
      return {
        loading: true,
        error: null,
        count: 0,
      };
    })
    .handleAction([hl7SuccessMessageCountActions.failure], (_, { payload }) => {
      return {
        loading: false,
        error: payload.code,
        count: 0,
      };
    }),
  erroredMessages: createReducer<Hl7MessageCount | null, RootAction>(
    initialState.erroredMessages,
  )
    .handleAction([hl7ErroredMessageCountActions.success], (_, { payload }) => {
      return {
        loading: false,
        error: null,
        count: payload,
      };
    })
    .handleAction([hl7ErroredMessageCountActions.request], _ => {
      return {
        loading: true,
        error: null,
        count: 0,
      };
    })
    .handleAction([hl7ErroredMessageCountActions.failure], (_, { payload }) => {
      return {
        loading: false,
        error: payload.code,
        count: 0,
      };
    }),
  unsentMessages: createReducer<Hl7MessageCount | null, RootAction>(
    initialState.unsentMessages,
  )
    .handleAction([hl7UnsentMessageCountActions.success], (_, { payload }) => {
      return {
        loading: false,
        error: null,
        count: payload,
      };
    })
    .handleAction([hl7UnsentMessageCountActions.request], _ => {
      return {
        loading: true,
        error: null,
        count: 0,
      };
    })
    .handleAction([hl7UnsentMessageCountActions.failure], (_, { payload }) => {
      return {
        loading: false,
        error: payload.code,
        count: 0,
      };
    }),
});

export const hl7MessageSearchReducer = combineReducers<Hl7MessageSearchState>({
  loading: createReducer<boolean>(initialMessageSearchState.loading)
    .handleAction([hl7MessageSearchActions.request], () => true)
    .handleAction(
      [
        hl7MessageSearchActions.cancel,
        hl7MessageSearchActions.success,
        hl7MessageSearchActions.failure,
        invalidSessionAction.request,
      ],
      () => false,
    ),
  results: createReducer<SearchHl7MessagesResponse, RootAction>(
    initialMessageSearchState.results,
  )
    .handleAction(
      [hl7MessageSearchActions.success],
      (_, { payload }) => payload,
    )
    .handleAction([hl7MessageSearchActions.failure], state => ({
      pageNumber: state.pageNumber,
      pageSize: state.pageSize,
      messages: [],
      totalMessages: state.totalMessages,
    }))
    .handleAction(
      [invalidSessionAction.request],
      () => initialMessageSearchState.results,
    ),
  error: createReducer<string | null, RootAction>(
    initialMessageSearchState.error,
  )
    .handleAction(
      [
        hl7MessageSearchActions.request,
        hl7MessageSearchActions.success,
        invalidSessionAction.request,
      ],
      () => null,
    )
    .handleAction(
      [hl7MessageSearchActions.failure],
      (_, { payload }) => payload.code || null,
    ),
  messages: createReducer<Hl7MessageState[], RootAction>(
    initialMessageSearchState.messages,
  )
    .handleAction([hl7MessageActions.request], (state, { payload }) => {
      const currentMessage = state.find(message => message.id === payload);
      return currentMessage
        ? updateMessageState(state, { ...currentMessage, loading: true })
        : addMessageToState(state, {
            id: payload,
            loading: true,
            error: null,
            message: null,
          });
    })
    .handleAction([hl7MessageActions.success], (state, { payload }) => {
      const currentMessage = state.find(message => message.id === payload.id);
      return currentMessage
        ? updateMessageState(state, {
            ...currentMessage,
            loading: false,
            message: payload,
          })
        : addMessageToState(state, {
            id: payload.id ?? '',
            loading: false,
            error: null,
            message: payload,
          });
    })
    .handleAction([hl7MessageActions.failure], (state, { payload }) => {
      const currentMessage = state.find(message => message.id === payload.id);

      return currentMessage
        ? updateMessageState(state, {
            ...currentMessage,
            error: payload.code,
            loading: false,
          })
        : addMessageToState(state, {
            id: payload.id,
            error: payload.code,
            loading: false,
            message: null,
          });
    }),
});

function updateMessageState(
  state: Hl7MessageState[],
  update: Hl7MessageState,
): Hl7MessageState[] {
  return state.map(x => (x.id === update.id ? { ...update } : x));
}

function addMessageToState(
  state: Hl7MessageState[],
  message: Hl7MessageState,
): Hl7MessageState[] {
  const newState = state.slice();
  newState.splice(0, 0, message);
  return newState;
}
