// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type {
  PatientDecrypted,
  ActivityDecrypted,
  PatientDemographicHistoryItemDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from 'src/api/types';
import PatientDemographicUpdatesTable from './PatientDemographicUpdatesTable';
import classes from './PatientDemographics.module.less';
import PatientActivityHistoryTable from '../patient-activity-history/PatientActivityHistoryTable';
import { usePatientDetailsPagination } from '../../store/hooks';
import { patientDetailsPaginationSelector } from '../../store/selectors';
import PatientHl7MessagesTable from '../patient-hl7-messages/PatientHl7MessagesTable';
import { useState } from 'react';

export interface PatientDetailsTabsPanelProps {
  patient: PatientDecrypted | null;
  activities: ActivityDecrypted[] | null;
  demographicUpdates: PatientDemographicHistoryItemDecrypted[] | null;
  hl7Messages: PatientHl7MessageSummaryDecrypted[] | null;
  paginationComponent: JSX.Element;
}

const PatientDetailsTabsPanel: React.FC<PatientDetailsTabsPanelProps> = ({
  patient,
  activities,
  demographicUpdates,
  hl7Messages,
  paginationComponent,
}) => {
  const { t } = useTranslation();
  const { pagination: pagination, updatePagination } =
    usePatientDetailsPagination();
  const paginationRedux = useSelector(patientDetailsPaginationSelector);

  const [latestTableRecordDateTime, setLatestTableRecordDateTime] =
    useState('');

  const getCurrentReduxPagination = (key: string) => {
    switch (key) {
      case '1':
        return paginationRedux.activities;
      case '2':
        return paginationRedux.demographicUpdates;
      case '3':
        return paginationRedux.hl7Messages;
      default:
        return paginationRedux.activities;
    }
  };

  const handleTabChange = (key: string) => {
    const currentReduxPagination = getCurrentReduxPagination(key);

    updatePagination({
      activeTab: key,
      pageNumber: currentReduxPagination.pageNumber,
      pageSize: currentReduxPagination.pageSize,
    });
  };

  const items = [
    {
      key: '1',
      label: (
        <span data-testid="patient-tab-activity">
          {t('patient-details.navigation-tab-options.activities')}
        </span>
      ),
      children: (
        <PatientActivityHistoryTable
          patient={patient}
          patientActivities={activities}
          paginationComponent={paginationComponent}
          setLatestTableRecordDateTime={setLatestTableRecordDateTime}
        />
      ),
    },
    {
      key: '2',
      label: (
        <span data-testid="patient-tab-demographic-updates">
          {t('patient-details.navigation-tab-options.demographic-history')}
        </span>
      ),
      children: (
        <PatientDemographicUpdatesTable
          patient={patient}
          demographicUpdates={demographicUpdates}
          paginationComponent={paginationComponent}
          setLatestTableRecordDateTime={setLatestTableRecordDateTime}
        />
      ),
    },
    {
      key: '3',
      label: (
        <span data-testid="patient-tab-hl7-messages">
          {t('patient-details.navigation-tab-options.hl7-messages')}
        </span>
      ),
      children: (
        <PatientHl7MessagesTable
          patient={patient}
          patientHl7Messages={hl7Messages}
          paginationComponent={paginationComponent}
          setLatestTableRecordDateTime={setLatestTableRecordDateTime}
        />
      ),
    },
  ];

  return (
    <>
      {latestTableRecordDateTime !== '' && (
        <div
          className={classes.latestTableRecordDateTime}
          data-testid="patient-details-table--latest-record-date-time"
        >
          {`${t('patient-details.latest-table-record-date-time')} ${latestTableRecordDateTime}`}
        </div>
      )}
      <Tabs
        className={classes.patientTabsPanel}
        activeKey={pagination.activeTab}
        onChange={handleTabChange}
        items={items}
        data-testid="patient-details-tabs-panel"
        destroyInactiveTabPane
      />
    </>
  );
};

export default PatientDetailsTabsPanel;
