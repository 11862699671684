// Copyright 2022, Imprivata, Inc.  All rights reserved.
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type {
  ActivitySubtype,
  ActivityType,
  TimePeriodType,
} from '@imprivata-cloud/adminapi-client';

export class DashboardTrendDataRequest implements IDashboardTrendDataRequest {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  timePeriodType?: TimePeriodType;
  activityTypes?: ActivityType[];
  activitySubtypes?: ActivitySubtype[];
  timeZoneId?: string | null;
  constructor(data?: IDashboardTrendDataRequest) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
}

export interface IDashboardTrendDataRequest {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  timePeriodType?: TimePeriodType;
  activityTypes?: ActivityType[];
  activitySubtypes?: ActivitySubtype[];
  timeZoneId?: string | null;
}

export enum TimePeriodOptionIdentifier {
  Last24Hours = 'last-24-hours',
  Last7Days = 'last-7-days',
  Last30Days = 'last-30-days',
  Last12Months = 'last-12-months',
}

export interface TimePeriodOption {
  timePeriodType: TimePeriodType;
  optionType: TimePeriodOptionIdentifier;
  label: string;
}
