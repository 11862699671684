// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TimePeriodType, type ActivityType } from '@imprivata-cloud/adminapi-client';
import type { MappedChartDatum } from '../../../api/types';
import type { Moment } from 'moment-timezone';
import type { RootState } from '../../../store/rootReducer';
import { type DashboardTrendDataRequest, type TimePeriodOption } from '../types';
import { trendDataChartActions } from './actions';
import { getStartDate } from '../components/dashboard-view/DashboardView';
import moment from 'moment-timezone';

export const trendDataChartState = (state: RootState): MappedChartDatum[] => {
  return state.dashboard.trendData.mappedChartData;
};

export const trendDataChartTotalsState = (
  state: RootState,
): {
  [key in keyof typeof ActivityType]?: number;
} => {
  return state.dashboard.trendData.chartDataTotals;
};

export const isLoadingState = (state: RootState): boolean => {
  return state.dashboard.trendData.loading;
};

export const latestActivityDateTimeState = (
  state: RootState,
): Moment | null => {
  return state.dashboard.trendData.latestActivityDateTime;
};

const TIME_PERIOD_STORAGE_KEY = 'DASHBOARD_TIME_PERIOD_OPTION';

export function useGetMappedTrendDataChartData(
  request: DashboardTrendDataRequest,
): {
  mappedChartData: MappedChartDatum[];
  chartTotals: {
    [key in keyof typeof ActivityType]?: number;
  };
  isLoading: boolean;
  latestActivityDateTime: Moment | null;
  fetchTrendData: (request: DashboardTrendDataRequest) => void;
  saveTimePeriodOption: (option: TimePeriodOption) => void;
  loadTimePeriodOption: () => TimePeriodOption | null;
  clearTimePeriodOption: () => void;
} {
  const dispatch = useDispatch();
  const mappedChartData = useSelector(trendDataChartState);
  const chartTotals = useSelector(trendDataChartTotalsState);
  const isLoading = useSelector(isLoadingState);
  const latestActivityDateTime = useSelector(latestActivityDateTimeState);

  const useMountEffect = () =>
    useEffect(() => {
      const storedOption = loadTimePeriodOption();
      if (storedOption && storedOption.timePeriodType in TimePeriodType) {
        request.timePeriodType = storedOption.timePeriodType;
        request.startDate = getStartDate(storedOption);
        request.endDate = moment();
      }
      dispatch(trendDataChartActions.request(request));

      return () => {
        dispatch(trendDataChartActions.cancel());
      };
    }, []);
  useMountEffect();

  const fetchTrendData = (request: DashboardTrendDataRequest) => {
    dispatch(trendDataChartActions.request(request));
  };

  const saveTimePeriodOption = (option: TimePeriodOption) => {
    localStorage.setItem(TIME_PERIOD_STORAGE_KEY, JSON.stringify(option));
  };

  const loadTimePeriodOption = (): TimePeriodOption | null => {
    const storedOption = localStorage.getItem(TIME_PERIOD_STORAGE_KEY);
    return storedOption ? JSON.parse(storedOption) : null;
  };

  const clearTimePeriodOption = () => {
    localStorage.removeItem(TIME_PERIOD_STORAGE_KEY);
  };

  return {
    mappedChartData,
    chartTotals,
    isLoading,
    latestActivityDateTime,
    fetchTrendData,
    saveTimePeriodOption,
    loadTimePeriodOption,
    clearTimePeriodOption,
  };
}
