// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import type {
  ActivityDecrypted,
  PatientDemographicHistoryItemDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from 'src/api/types';
import {
  demographicUpdatesSelector,
  patientActivityHistorySelector,
  patientHl7MessagesSelector,
} from '../../patient-search/store/selectors';
import {
  patientDeletionErrorSelector,
  patientDeletionOccurredSelector,
  patientDetailsPaginationSelector,
} from './selectors';
import { getPathWithQuery } from '../../../utils/routingHelpers';
import { updatePaginationAction } from './actions';

interface UsePaginationOptions {
  tableName: string; // to differentiate between tables
}

interface PaginationState {
  filters: Record<string, any>;
  sorter: any;
}

export const usePatientDetails = (): {
  deletionError: ReturnType<typeof patientDeletionErrorSelector>;
  patientDeletionOccurred: ReturnType<typeof patientDeletionOccurredSelector>;
} => {
  const deletionError = useSelector(patientDeletionErrorSelector);
  const patientDeletionOccurred = useSelector(patientDeletionOccurredSelector);
  return {
    deletionError,
    patientDeletionOccurred,
  };
};

export const usePatientDetailsPagination = (): {
  pagination: {
    activeTab: string;
    pageNumber: number;
    pageSize: number;
  };
  updatePagination: (filters: {
    activeTab: string;
    pageNumber: number;
    pageSize: number;
    totalCount?: number;
  }) => void;
  currentCount: {
    totalCount: number;
    pageCount: number;
    startCount: number;
    endCount: number;
  };
  currentItems: {
    activities: ActivityDecrypted[] | null;
    demographicUpdates: PatientDemographicHistoryItemDecrypted[] | null;
    hl7Messages: PatientHl7MessageSummaryDecrypted[] | null;
  };
  updateTotalCount: (totalCount: number) => void;
} => {
  const activeTabParam = 'activeTab';
  const pageNumberParam = 'pageNumber';
  const pageSizeParam = 'pageSize';
  const totalCountParam = 'totalCount';

  const defaultActiveTab = '1';
  const defaultPageSize = 25;
  const defaultPageNumber = 1;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // Selectors to get data from redux store
  const paginationRedux = useSelector(patientDetailsPaginationSelector);

  const demographicUpdates = useSelector(demographicUpdatesSelector);
  const activities = useSelector(patientActivityHistorySelector);
  const hl7Messages = useSelector(patientHl7MessagesSelector);

  const getActiveTabFromQuery = (activeTabParam: string | null) => {
    if (!activeTabParam) {
      return defaultActiveTab;
    }

    return activeTabParam;
  };

  const getPageNumberFromQuery = (pageNumberParam: string | null) => {
    if (!pageNumberParam || isNaN(+pageNumberParam) || +pageNumberParam < 1) {
      return defaultPageNumber;
    }

    return +pageNumberParam;
  };

  const getPageSizeFromQuery = (pageSizeParam: string | null) => {
    if (!pageSizeParam || isNaN(+pageSizeParam) || +pageSizeParam < 1) {
      return defaultPageSize;
    }

    return +pageSizeParam;
  };

  const getActiveTabName = (activeTab: string) => {
    switch (activeTab) {
      case '1':
        return 'activities';
      case '2':
        return 'demographicUpdates';
      case '3':
        return 'hl7Messages';
      default:
        return 'activities';
    }
  }

  const getCurrentReduxPagination = (activeTab: string) => {
    switch (activeTab) {
      case '1':
        return paginationRedux.activities;
      case '2':
        return paginationRedux.demographicUpdates;
      case '3':
        return paginationRedux.hl7Messages;
      default:
        return paginationRedux.activities;
    }
  }

  const getTotalCountFromQuery = (totalCountParam: string | null): number => {
    if (!totalCountParam || isNaN(+totalCountParam)) {
      if (paginationRedux.activeTab === '1') 
        return +paginationRedux.activities.totalCount
      else if (paginationRedux.activeTab === '2') 
        return +paginationRedux.demographicUpdates.totalCount
      else 
        return +paginationRedux.hl7Messages.totalCount; // default to the redux value if not in URL
    }
    return +totalCountParam;
  };

  const initialSearchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState<{
    activeTab: string;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  }>({
    activeTab: getActiveTabFromQuery(initialSearchParams.get(activeTabParam)),
    pageNumber: getPageNumberFromQuery(
      initialSearchParams.get(pageNumberParam),
    ),
    pageSize: getPageSizeFromQuery(initialSearchParams.get(pageSizeParam)),
    totalCount: getTotalCountFromQuery(
      initialSearchParams.get(totalCountParam),
    ),
  });

  const [currentCount, setCurrentCount] = useState({
    totalCount: 0,
    pageCount: 0,
    startCount: 0,
    endCount: 0,
  });

  // Ref to track if it's the first render (page refresh)
  const isInitialRender = useRef(true);

  // Update pagination every time the URL changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTab = getActiveTabFromQuery(searchParams.get(activeTabParam));

    const currentReduxPagination = getCurrentReduxPagination(activeTab);

    const updatedPagination = {
      activeTab,
      pageNumber: currentReduxPagination.pageNumber,
      pageSize: currentReduxPagination.pageSize,
      totalCount: getTotalCountFromQuery(searchParams.get(totalCountParam)),
    };

    if (isInitialRender.current) {
      setPagination({
        activeTab: getActiveTabFromQuery(searchParams.get(activeTabParam)),
        pageNumber: getPageNumberFromQuery(searchParams.get(pageNumberParam)),
        pageSize: getPageSizeFromQuery(searchParams.get(pageSizeParam)),
        totalCount: getTotalCountFromQuery(searchParams.get(totalCountParam)),
      });
      // update on redux too, only for the active tab
      dispatch(
        updatePaginationAction({
          [getActiveTabName(activeTab)]: {
            pageNumber: getPageNumberFromQuery(
              searchParams.get(pageNumberParam),
            ),
            pageSize: getPageSizeFromQuery(searchParams.get(pageSizeParam)),
            totalCount: getTotalCountFromQuery(
              searchParams.get(totalCountParam),
            ),
          },
          activeTab,
        }),
      );

      isInitialRender.current = false; // Mark that initial render is done
    } else {
      setPagination(updatedPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, paginationRedux]);

  // Update current count every time the pagination changes
  useEffect(() => {
    const currentCount = calculateCounts(
      pagination.totalCount,
      pagination.pageNumber,
      pagination.pageSize,
    );
    setCurrentCount(currentCount);
  }, [pagination]);

  const updatePagination = (filters: {
    activeTab: string;
    pageNumber: number;
    pageSize: number;
    totalCount?: number;
  }) => {
    const tabKey = getActiveTabName(filters.activeTab);
    const totalCount =
      filters.totalCount ?? getCurrentReduxPagination(filters.activeTab).totalCount;

    dispatch(
      updatePaginationAction({
        [tabKey]: {
          pageNumber: filters.pageNumber,
          pageSize: filters.pageSize,
          totalCount: totalCount,
        },
        activeTab: filters.activeTab,
      }),
    );

    // Update the URL with totalCount
    const queryParams = [
      `${activeTabParam}=${filters.activeTab}`,
      `${pageNumberParam}=${filters.pageNumber}`,
      `${pageSizeParam}=${filters.pageSize}`,
    ];

    if (totalCount !== 0) {
      queryParams.push(`${totalCountParam}=${totalCount}`);
    }

    history.push(getPathWithQuery('', queryParams, true));
  };

  const updateTotalCount = (totalCount: number) => {
    const searchParams = new URLSearchParams(location.search);
    const tabKey = getActiveTabName(pagination.activeTab);
    dispatch(
      updatePaginationAction({
        [tabKey]: {
          pageNumber: getPageNumberFromQuery(searchParams.get(pageNumberParam)),
          pageSize: getPageSizeFromQuery(searchParams.get(pageSizeParam)),
          totalCount: totalCount,
        },
        activeTab: getActiveTabFromQuery(searchParams.get(activeTabParam)),
      }),
    );
  };

  const calculateCounts = (
    totalItems: number,
    pageNumber: number,
    pageSize: number,
  ) => {
    const pageCount = pageSize > 0 ? Math.ceil(totalItems / pageSize) : 0;
    const startCount = totalItems ? (pageNumber - 1) * pageSize + 1 : 0;
    const endCount =
      totalItems < pageSize
        ? totalItems
        : Math.min(totalItems, pageNumber * pageSize);
    return { totalCount: totalItems, pageCount, startCount, endCount };
  };

  const currentItems = {
    activities: activities?.activities || null,
    demographicUpdates: demographicUpdates?.demographicsHistory || null,
    hl7Messages: hl7Messages || null,
  };

  return {
    pagination: pagination,
    updatePagination,
    currentCount,
    currentItems,
    updateTotalCount,
  };
};

export const useFilteringAndSorting = ({ tableName }: UsePaginationOptions) => {
  // State to track filters and sorting
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [sorter, setSorter] = useState<any>({});

  // Redux selector to get the current active tab
  const paginationRedux = useSelector(patientDetailsPaginationSelector);
  const activeTab = paginationRedux.activeTab;

  // Define a single localStorage key for both tables
  const localStorageKey = `filtering_sorting_state`;

  const initialLoad = useRef(true);

  // Load saved filter and sorter values from local storage on mount
  useEffect(() => {
    const savedState = localStorage.getItem(localStorageKey);
    if (savedState) {
      const parsedState: {
        activeTab: string;
        filters: Record<string, any>;
        sorter: any;
      } = JSON.parse(savedState);

      // Only load filters and sorter if activeTab matches tableName
      const shouldLoad =
        (parsedState.activeTab === '1' && tableName === 'activities') ||
        (parsedState.activeTab === '2' && tableName === 'demographicUpdates') ||
        (parsedState.activeTab === '3' && tableName === 'hl7Messages');

      if (shouldLoad) {
        setFilters(parsedState.filters || {});
        setSorter(parsedState.sorter || {});
      }
    }
  }, [localStorageKey, tableName]);

  // Save filters, sorter, and activeTab values to local storage when they change
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    // Only save if the activeTab corresponds to the current tableName
    const shouldSave =
      (activeTab === '1' && tableName === 'activities') ||
      (activeTab === '2' && tableName === 'demographicUpdates') ||
      (activeTab === '3' && tableName === 'hl7Messages');

    if (shouldSave) {
      const stateToSave = {
        activeTab,
        filters,
        sorter,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(stateToSave));
    }
  }, [filters, sorter, activeTab, localStorageKey, tableName]);

  // Handler functions to update filters and sorter in state
  const updateFilters = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  const updateSorter = (newSorter: any) => {
    setSorter(newSorter);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(localStorageKey);
  };

  return {
    filters,
    sorter,
    updateFilters,
    updateSorter,
    clearLocalStorage,
  };
};
