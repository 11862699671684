// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  switchMap,
  map,
  catchError,
  tap,
  filter,
  finalize,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import type { RootState } from 'src/store/rootReducer';
import type { Epic } from 'redux-observable';
import type { RootAction } from '../../../store/rootAction';
import { ErrorCodes } from '../constants';

import {
  logoutAction,
  invalidSessionAction,
  getTenantTypeAction,
  startClientUserSessionAction,
} from './actions';
import {
  endLogoutSpan,
  endGetTenantTypeSpan,
  startLogoutSpan,
  startGetTenantTypeSpan,
  startCreateUserSessionTracingSpan,
  endCreateUserSessionTracingSpan
} from '../tracing';
import {
  saveTenantId,
  clearTenantId,
  clearHasValidSession,
  clearTenantType,
  saveHasValidSession,
  saveTenantType,
  clearUsername,
  clearCodingContext,
} from '../utils';
import {
  logout$,
  startClientUserSession$,
} from '../../../api/services/sessionService';
import { getTenantType$ } from '../../../api/services/tenantService';

export const logoutEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(logoutAction.request)),
    tap(() => {
      startLogoutSpan();
      sessionStorage.clear();
    }),
    switchMap(() => {
      return logout$().pipe(
        tap(() => {
          endLogoutSpan();
        }),
        map(() => logoutAction.success()),
        catchError(error => {
          endLogoutSpan(error);
          return of(logoutAction.success());
        }),
        finalize(() => {
          // remove auth info from persistent storage
          clearHasValidSession();
          clearTenantId();
          clearTenantType();
          clearCodingContext();
          clearUsername();
        }),
      );
    }),
  );

export const invalidSessionEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(invalidSessionAction.request)),
    tap(() => {
      sessionStorage.clear();
    }),
    switchMap(({ payload }) => {
      // remove auth info from persistent storage
      clearHasValidSession();
      clearTenantId();
      clearTenantType();

      return of(invalidSessionAction.success(payload));
    }),
    catchError(() => {
      return of(invalidSessionAction.failure());
    }),
  );

export const getTenantTypeEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(getTenantTypeAction.request)),
    tap(() => {
      startGetTenantTypeSpan();
    }),
    switchMap(() => {
      return getTenantType$().pipe(
        tap(type => {
          endGetTenantTypeSpan();
          saveTenantType(type);
        }),
        map(type => {
          if (type) {
            return getTenantTypeAction.success(type);
          } else {
            return getTenantTypeAction.failure();
          }
        }),
        catchError(error => {
          endGetTenantTypeSpan(error);
          return of(getTenantTypeAction.failure());
        }),
      );
    }),
  );

export const startClientUserSessionEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(startClientUserSessionAction.request)),
    tap(() => {
      startCreateUserSessionTracingSpan();
    }),
    switchMap(({ payload }) => {
      return startClientUserSession$().pipe(
        map(() => {
          saveTenantId(payload.tenantId);
          saveHasValidSession();

          endCreateUserSessionTracingSpan();

          return startClientUserSessionAction.success(true);
        }),
        catchError(error => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          endCreateUserSessionTracingSpan({ error });

          clearHasValidSession();
          return of(
            startClientUserSessionAction.failure(ErrorCodes.NO_PERMISSION),
          );
        }),
      );
    }),
  );

export const loginEpics = combineEpics(
  logoutEpic,
  invalidSessionEpic,
  getTenantTypeEpic,
  startClientUserSessionEpic,
);
